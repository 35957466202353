<template>
  <Popover class="fixed left-0 w-full top-0 z-20 bg-white shadow">
    <div class="max-w-7xl mx-auto px-4 sm:px-6">
      <div class="flex justify-between items-center py-6 md:py-8 md:justify-start md:space-x-5">
        <div class="flex justify-start lg:w-0 flex-1">
          <router-link to="/">
            <span class="sr-only">Ted Michaels</span>
            <div class="flex items-center">
              <img class="h-5 md:h-6 w-auto" src="@/assets/logo.svg" alt="Company Name" />
            </div>
          </router-link>
        </div>

        <PopoverGroup as="nav" class="hidden md:flex space-x-6 xl:space-x-7 flex-1 items-center justify-center">
          <NavItem
            v-for="item in navigation"
            :path="item.path"
            :key="item.label"
            :dropdownItems="item.dropdownItems"
          >
            {{ item.label }}
          </NavItem>
        </PopoverGroup>

        <div class="flex-1 md:flex items-center justify-end hidden">
          <div class="flex items-center space-x-3">
           <a class="transition hover:opacity-75" target="_blank" href="https://twitter.com/mrtedmichaels">
              <img src="@/assets/twitter.svg" alt="" />
            </a>
            <a class="transition hover:opacity-75" title="Visit Facebook page" href="https://www.facebook.com/ted.michaels.1" target="_blank">
                  <img src="@/assets/facebook.svg" alt="" />
            </a>
            <a class="transition hover:opacity-75" title="Visit LinkedIn page" href="https://www.linkedin.com/in/ted-michaels-2b2112/"  target="_blank">
              <img src="@/assets/linkedin.svg" alt="" />
            </a>
            <a class="transition hover:opacity-75" target="_blank"  href="https://www.instagram.com/tedmichaels1954/">
              <img src="@/assets/instagram.svg" alt="" />
            </a>
          </div>
        </div>

        <div class="-mr-2 -my-2 md:hidden">
          <PopoverButton
            class="
              rounded-md
              p-2
              inline-flex
              items-center
              justify-center
              text-white
              hover:text-gray-500
              transition
              hover:bg-white
            "
          >
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6 text-accent" aria-hidden="true" />
          </PopoverButton>
        </div>
      </div>
    </div>

    <transition
      enter-active-class="duration-200 ease-out"
      enter-from-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="duration-100 ease-in"
      leave-from-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
    >
      <PopoverPanel
        focus
        class="absolute top-0 inset-x-0 z-10 p-2 transition transform origin-top-right md:hidden"
      >
        <div
          class="
            rounded-lg
            shadow-lg
            ring-1 ring-black ring-opacity-5
            bg-white
            divide-y-2 divide-gray-50
          "
        >
          <div class="pt-5 pb-6 px-5">
            <div class="flex items-center justify-between">
              <router-link to="/">
                <img class="h-5 w-auto" src="@/assets/logo.svg" alt="Company name" />
              </router-link>
              <div class="-mr-2">
                <PopoverButton
                  class="
                    rounded-md
                    p-2
                    inline-flex
                    items-center
                    justify-center
                    transition
                    hover:bg-accent-lightest
                  "
                >
                  <span class="sr-only">Close menu</span>
                  <XIcon class="h-6 w-6 text-accent" aria-hidden="true" />
                </PopoverButton>
              </div>
            </div>
            <div class="mt-6">
              <nav class="grid gap-y-8">
                <PopoverButton>
                  <router-link
                    v-for="item in navigation"
                    :key="item.label"
                    :to="item.path"
                    class="nav-item-mobile"
                  >
                    <span class="nav-item-mobile__text">{{ item.label }}</span>
                  </router-link>
                </PopoverButton>
              </nav>
            </div>
          </div>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
  <div class="mb-16 md:mb-20" />
</template>

<script>
import NavItem from "@/components/nav/NavItem.vue";
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from "@headlessui/vue";
import { MenuIcon, XIcon } from "@heroicons/vue/outline";
import { navigation } from "@/data";

export default {
  components: {
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    MenuIcon,
    XIcon,
    NavItem,
  },
  setup() {
    return { navigation };
  },
};
</script>
